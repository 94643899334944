@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}

body {
  margin: 0;
  font-family: "Open Sans",sans-serif;
  background-color: #F6F6F6;
  overflow-x: hidden;
  color: #222220!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


input[type="text"], input[type="email"], input[type="number"], input[type="tel"], input[type="password"], textarea, select {
  width: 100%;
  padding: 20px!important;
  font-size: 13px!important;
  font-family: inherit;
  color: #000;
  background-color: #f6f6f6;
  border: 1px solid #d0d1cc !important;
  border-radius: 0.375rem !important;
}

.btn-tau {
  color: #fff;
  border: 2px solid #000;
  border-radius: 0.375rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: .7rem;
  font-weight: 500;
  height: 48px;
  background-color: #000;
}

h1 {
  font-size: 2.95em;
  padding: 1.25rem 0 1rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 3px;
}

table.table {
  border: 1px solid #d0d1cc;
}

thead {
  color: #fff;
  border: 2px solid #000;
  background-color: #000;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 1;
  font-size: .8rem;
  font-weight: 500;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.shadow-drop-bottom {
  -webkit-box-shadow:0 20px 20px -20px rgba(34,34,32,.25);
  box-shadow:0 20px 20px -20px rgba(34,34,32,.25)
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: .7s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: .7s;
  animation-fill-mode: both;
}



@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
  animation-duration: .7s;
  animation-fill-mode: both;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
  animation-duration: .7s;
  animation-fill-mode: both;
}

.border-t-0 {
  border-top-width: 0!important;
}